<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <form-v-select
            v-if="userType != 'vendor'"
            v-model="vendor"
            dataurl="vendor"
            label="Vendor"
            placeholder="Select Vendor"
          />
          <b-form-group
            label-cols-md="4"
            label="Tanggal Kadaluarsa"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Kadaluarsa"
              />
              <!-- <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              /> -->
            </b-input-group>
          </b-form-group>
          <form-v-select
            v-model="variant"
            dataurl="vehicle-variant"
            label="Model"
            placeholder="Select Model"
            :item_text="'model'"
          />
          <b-form-group
            label-cols-md="4"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua Status' },
                { value:'created',text:'STNK Aktif'},
                { value:'approved',text:'STNK Segera Kadaluarsa'},
                { value:'rejected',text:'STNK Kadaluarsa'},
                { value:'created',text:'KIR Aktif'},
                { value:'approved',text:'KIR Segera Kadaluarsa'},
                { value:'rejected',text:'SIR Kadaluarsa'},
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="4"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+(userType == 'vendor'?'?filter[contact_id]='+user.id:'')"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
        <template #cell(status)="data">
          <div>
            <span
              style="margin:2px;"
              class="badge"
              :class="setStatus(data.item.stnk_date).color"
            >
              {{ data.item.stnk_date?'STNK ':'' }}{{ setStatus(data.item.stnk_date).value }}
            </span>
            <span
              style="margin:2px;"
              class="badge"
              :class="setStatus(data.item.keur_date).color"
            >
              {{ data.item.keur_date?'Keur ':'' }}{{ setStatus(data.item.keur_date).value }}
            </span>
          </div>
        </template>
        <template #cell(_)="data">
            <b-button
              class="mr-1 btn-icon"
              size="sm"
              variant="primary"
              @click="actionShow(data)" >
              <feather-icon icon="FolderIcon" />
            </b-button>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend, BModal, BSpinner, BForm } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    FormVSelect,
    BForm,
    BSpinner
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/vehicle-keur",
      baseroute:"vehicles",
      title:"Daftar Pajak & Kir",
      fields: [
        { key: 'number', label: 'Nopol', sortable: true },
        { key: 'variant.model', label: 'Model', sortable: true },
        { key: 'contact.name', label: 'Kepemilikan Kendaraan', sortable: true },
        { key: 'stnk_date', label: 'Tgl Kadaluarsa STNK', sortable: true },
        { key: 'keur_date', label: 'Tgl Kadaluarsa KEUR', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      filter :[],
      loading:false,
      vendor:null,
      variant:null,
      status:null,
    }
  },
  created(){
    if(this.$route.meta.user){
      this.userType = this.$route.meta.user.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$route.meta.user.contact
    }
  },
  mounted(){
    // this.fetchDataMutation()
  },
  methods:{
    show(){
      // if(this.date_start != "" && this.date_end != "")
      //   this.filter['between[request_date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.vendor = null
      this.show()
    },
    fetchDataMutation(){
      this.$http.get(this.dataurl+'?status=sent').then(res=>{
        this.optionMutation = res.data.data
      })
    },
    hideForm(){
      this.$bvModal.hide('modal-select')
      this.mutation = null
      this.$refs.simpleRules.reset()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          this.$http.put(this.dataurl+'/'+this.mutation.id+'/receive')
          .then(()=>{
            this.successSubmit()
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$refs.basetable.fetchData()
      this.hideForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    setStatus(date){
      let val = {color:'bg-secondary', value:'Tidak Diketahui'}
      if(date){
        if(moment.duration(moment(date).diff(moment())).asHours() >= 720){
          val.color = 'bg-primary'
          val.value = 'Aktif'
        }else if(moment.duration(moment(date).diff(moment())).asHours() < 720 
          && moment.duration(moment(date).diff(moment())).asHours() > 0){
          val.color = 'bg-warning'
          val.value = 'Segera Kadaluarsa'
        }else{
          val.color = 'bg-danger'
          val.value = 'Kadaluarsa '
        }
      }
      return val
    },
    actionShow(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id, tabActive:'info'} })
    }
  }
}
</script>
<style lang="">

</style>
